/* eslint-disable react/jsx-filename-extension */
import React from "react"
import { Link, graphql, useStaticQuery } from "gatsby"
import Layout from "./pageLayout"
import { Breadcrumb } from "gatsby-plugin-breadcrumb"
import { MDXProvider } from "@mdx-js/react"
import { MDXRenderer } from "gatsby-plugin-mdx"
import { CSSTransition } from "react-transition-group"

import WaitingSection from "../components/waitingSection.js"
import PriceSection from "../components/priceSection.js"
import ProjectCard from "../components/projectCard.js"

import * as homeStyles from "../pages/index.module.css"
import * as priceSectionStyles from "../components/priceSection.module.css"
import * as servicePageStyles from "./servicePage.module.css"

import ScrollAnimation from "react-animate-on-scroll"
import metaImg from "../images/meta-image.jpg"
import "animate.css"
import ContactSection from "../components/contactSection.js"

export default function ServicePage({ pageContext, data }) {
  const nodeData = data.mdx
  const {
    breadcrumb: { crumbs },
  } = pageContext

  const customCrumbLabel = nodeData.frontmatter.title

  let metaDescription

  if (nodeData.frontmatter.metaDescription) {
    metaDescription = nodeData.frontmatter.metaDescription
  } else {
    metaDescription = `Professional ${
      nodeData.frontmatter.category + " " + nodeData.frontmatter.title
    } service in Portsmouth, Gosport, Fareham, Southampton, Chichester and Hampshire `
  }

  const icons = [
    <svg
      alt=""
      className={servicePageStyles.carIcon}
      version="1.1"
      id="SmallCar"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 98.54 98.54"
    >
      <path
        d="M78.823,62.794c0,0.359,0.029,0.71,0.074,1.059c0.521,4.149,4.056,7.361,8.347,7.361c4.407,0,8.019-3.389,8.384-7.7
  c0.02-0.238,0.037-0.478,0.037-0.72c0-4.65-3.771-8.421-8.421-8.421C82.593,54.374,78.823,58.144,78.823,62.794z M92.47,62.08
  h-2.42c-0.068-0.264-0.172-0.511-0.306-0.741l1.718-1.718C91.994,60.326,92.345,61.163,92.47,62.08z M91.462,65.995l-1.729-1.728
  c0.136-0.228,0.245-0.47,0.313-0.73h2.42C92.338,64.45,91.994,65.292,91.462,65.995z M87.974,65.596
  c0.259-0.067,0.504-0.166,0.729-0.299l1.712,1.714c-0.701,0.528-1.53,0.886-2.442,1.013L87.974,65.596L87.974,65.596z
  M87.974,57.571c0.918,0.127,1.755,0.483,2.459,1.019l-1.716,1.716c-0.23-0.138-0.479-0.242-0.744-0.312L87.974,57.571
  L87.974,57.571z M83.025,59.621l1.719,1.718c-0.135,0.229-0.238,0.478-0.306,0.741h-2.42
  C82.144,61.163,82.495,60.326,83.025,59.621z M86.515,59.994c-0.265,0.069-0.513,0.174-0.744,0.312l-1.716-1.717
  c0.704-0.534,1.542-0.89,2.459-1.018L86.515,59.994L86.515,59.994z M84.071,67.012l1.711-1.712
  c0.226,0.133,0.474,0.229,0.731,0.296v2.429C85.604,67.898,84.772,67.541,84.071,67.012z M82.01,63.537h2.438
  c0.069,0.26,0.172,0.505,0.306,0.731l-1.722,1.721C82.5,65.288,82.138,64.451,82.01,63.537z"
      />
      <path
        d="M9.198,62.794c0,0.359,0.03,0.71,0.074,1.059c0.521,4.149,4.056,7.361,8.347,7.361c4.408,0,8.02-3.389,8.385-7.7
  c0.021-0.238,0.037-0.478,0.037-0.72c0-4.65-3.77-8.421-8.421-8.421S9.198,58.144,9.198,62.794z M22.846,62.08h-2.421
  c-0.067-0.264-0.171-0.511-0.305-0.741l1.718-1.718C22.369,60.326,22.72,61.163,22.846,62.08z M21.837,65.995l-1.728-1.728
  c0.135-0.228,0.245-0.47,0.313-0.73h2.42C22.714,64.45,22.369,65.292,21.837,65.995z M18.348,65.596
  c0.259-0.067,0.505-0.166,0.73-0.299l1.712,1.714c-0.701,0.528-1.531,0.886-2.443,1.013L18.348,65.596L18.348,65.596z
  M18.348,57.571c0.919,0.127,1.755,0.483,2.459,1.019l-1.715,1.716c-0.23-0.138-0.479-0.242-0.745-0.312L18.348,57.571
  L18.348,57.571z M13.4,59.621l1.72,1.718c-0.135,0.229-0.238,0.478-0.306,0.741h-2.421C12.519,61.163,12.87,60.326,13.4,59.621z
  M16.89,59.994c-0.265,0.069-0.514,0.174-0.744,0.312l-1.716-1.717c0.705-0.534,1.542-0.89,2.46-1.018V59.994z M14.447,67.012
  l1.711-1.712c0.226,0.133,0.473,0.229,0.731,0.296v2.429C15.979,67.898,15.148,67.541,14.447,67.012z M12.384,63.537h2.438
  c0.069,0.26,0.172,0.505,0.305,0.731l-1.722,1.721C12.875,65.288,12.513,64.451,12.384,63.537z"
      />
      <path
        d="M0.608,64.732l6.75-0.105c-0.029-0.172-0.066-0.342-0.089-0.517c-0.061-0.477-0.09-0.905-0.09-1.314
  c0-5.757,4.683-10.439,10.439-10.439c5.758,0,10.441,4.684,10.441,10.439c0,0.3-0.019,0.594-0.043,0.891
  c-0.018,0.209-0.053,0.413-0.083,0.619l48.903-0.768c-0.021-0.256-0.033-0.504-0.033-0.741c0-5.757,4.683-10.439,10.44-10.439
  c5.244,0,9.587,3.891,10.319,8.936c0.583-1.279,1.146-3.031,0.927-4.744c-0.418-3.258-3.258-6.421-3.425-8.012
  s0.534-2.897,0.116-5.152c-0.418-2.255-4.334-9.698-8.301-12.224c-2.882-1.835-4.958-3.084-13.711-3.586
  c-12.331-0.707-20.794,0.099-28.225,2.358c-4.418,1.344-19.541,9.212-24.126,11.751c-5.428,3.006-7.621,3.471-11.371,4.829
  c-4.333,1.569-7.086,5.012-8.172,7.614S-0.603,59.971,0.608,64.732z M69.973,41.622l-1.296-10.998
  c7.179,0.126,14.629,0.691,15.283,3.207c0.388,1.491-1.575,5.05-3.926,6.097C78.218,40.737,75.401,41.043,69.973,41.622z
  M34.938,39.997l0.82-2.399c9.711-5.215,14.664-7.011,26.704-7.011c0.551,0,1.121,0,1.702,0l-1.383,11.728
  c-12.625,1.121-27.527,2.039-27.527,2.039C36.453,41.891,34.938,39.997,34.938,39.997z"
      />
    </svg>,
    <svg
      alt=""
      className={servicePageStyles.carIcon}
      version="1.1"
      id="Capa_1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 99.382 99.382"
    >
      <path
        d="M17.001,49.693c-4.12,0-7.46,3.338-7.46,7.459c0,0.319,0.026,0.631,0.066,0.938c0.462,3.677,3.593,6.521,7.394,6.521
         c3.906,0,7.105-3.002,7.429-6.823c0.019-0.211,0.032-0.422,0.032-0.638C24.463,53.031,21.123,49.693,17.001,49.693z
          M13.264,54.342l1.522,1.521c-0.119,0.203-0.211,0.423-0.271,0.656H12.37C12.483,55.706,12.794,54.967,13.264,54.342z
          M12.364,57.812h2.16c0.062,0.229,0.15,0.447,0.27,0.646l-1.524,1.524C12.798,59.361,12.479,58.62,12.364,57.812z M16.356,61.787
         c-0.809-0.111-1.543-0.429-2.164-0.896l1.517-1.518c0.199,0.116,0.418,0.201,0.647,0.262V61.787z M16.356,54.672
         c-0.235,0.062-0.455,0.153-0.66,0.274l-1.521-1.521c0.625-0.475,1.366-0.788,2.181-0.901V54.672z M17.647,52.524
         c0.813,0.113,1.555,0.428,2.18,0.902l-1.52,1.52c-0.205-0.121-0.426-0.214-0.66-0.274V52.524z M17.647,61.786v-2.151
         c0.229-0.061,0.447-0.146,0.646-0.264l1.519,1.52C19.191,61.357,18.456,61.675,17.647,61.786z M20.738,59.988l-1.53-1.531
         c0.118-0.199,0.217-0.414,0.278-0.646h2.144C21.516,58.62,21.21,59.367,20.738,59.988z M19.487,56.52
         c-0.061-0.233-0.152-0.453-0.271-0.656l1.522-1.521c0.471,0.625,0.782,1.364,0.894,2.179L19.487,56.52L19.487,56.52z"
      />
      <path
        d="M78.611,49.758c-4.103,0-7.428,3.324-7.428,7.428c0,0.317,0.025,0.627,0.064,0.934c0.46,3.66,3.578,6.494,7.363,6.494
         c3.889,0,7.074-2.989,7.396-6.794c0.019-0.21,0.032-0.42,0.032-0.634C86.04,53.082,82.715,49.758,78.611,49.758z M74.891,54.387
         l1.516,1.516c-0.118,0.202-0.21,0.421-0.27,0.653h-2.136C74.112,55.744,74.422,55.009,74.891,54.387z M73.994,57.842h2.15
         c0.062,0.229,0.15,0.444,0.269,0.644l-1.519,1.52C74.427,59.386,74.108,58.646,73.994,57.842z M77.969,61.801
         c-0.805-0.112-1.535-0.429-2.154-0.896l1.51-1.51c0.198,0.115,0.417,0.201,0.645,0.26V61.801z M77.969,54.715
         c-0.233,0.061-0.453,0.152-0.656,0.272l-1.514-1.514c0.622-0.471,1.36-0.784,2.17-0.897V54.715z M79.255,52.576
         c0.812,0.113,1.549,0.428,2.17,0.898l-1.513,1.513c-0.204-0.12-0.423-0.213-0.657-0.272V52.576z M79.255,61.8v-2.145
         c0.229-0.059,0.446-0.146,0.645-0.262l1.511,1.512C80.792,61.371,80.061,61.687,79.255,61.8z M82.332,60.009l-1.523-1.524
         c0.117-0.199,0.216-0.412,0.276-0.643h2.134C83.106,58.646,82.802,59.389,82.332,60.009z M81.087,56.555
         c-0.06-0.232-0.15-0.451-0.27-0.653l1.516-1.516c0.469,0.622,0.778,1.357,0.889,2.169H81.087z"
      />
      <path
        d="M99.352,52.001c-0.026-0.983-0.331-1.941-0.882-2.759l-0.402-0.6l-1.757-4.635c-0.331-0.875-1.139-1.484-2.07-1.562
         c-1.728-0.146-4.663-0.368-7.465-0.471c-9.794-5.201-27.904-10.43-43.262-4.731c-3.151,1.169-12.154,5.744-12.154,5.744
         s-14.62-0.37-25.047,3.349c-4.108,1.465-6.699,5.543-6.266,9.884c0.087,0.869,0.215,1.642,0.341,2.266
         c0.199,0.987,1.014,1.731,2.015,1.842l6.487,0.711c-0.408-0.852-0.695-1.773-0.818-2.755c-0.052-0.401-0.078-0.772-0.078-1.132
         c0-4.967,4.041-9.008,9.008-9.008c4.968,0,9.01,4.042,9.01,9.008c0,0.26-0.017,0.514-0.038,0.768
         c-0.095,1.115-0.399,2.172-0.868,3.135h45.045l0.365-0.021c-0.4-0.842-0.683-1.753-0.804-2.72
         c-0.052-0.403-0.077-0.773-0.077-1.127c0-4.95,4.026-8.978,8.977-8.978s8.978,4.026,8.978,8.978c0,0.259-0.017,0.511-0.038,0.764
         c-0.062,0.734-0.223,1.438-0.453,2.11L88.112,60l6.979-0.923c1.214-0.161,2.285-0.876,2.898-1.936l0.695-1.199
         c0.479-0.83,0.721-1.777,0.695-2.735L99.352,52.001z M74.63,43.068H40.469c0,0,0.203-0.809-0.69-1.786
         c0,0,8.733-5.633,22.22-4.226c3.711,0.388,8.246,0.651,14.42,3.86L74.63,43.068z"
      />
    </svg>,
    <svg
      alt=""
      className={servicePageStyles.carIcon}
      version="1.1"
      id="Capa_1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 99.442 99.443"
    >
      <path
        d="M19.097,54.071c-4.175,0-7.561,3.383-7.561,7.56c0,0.324,0.026,0.641,0.066,0.951c0.469,3.729,3.642,6.611,7.494,6.611
       c3.959,0,7.202-3.042,7.53-6.916c0.018-0.214,0.033-0.428,0.033-0.646C26.66,57.454,23.275,54.071,19.097,54.071z M15.31,58.782
       l1.543,1.543c-0.121,0.206-0.214,0.429-0.274,0.665h-2.174C14.518,60.165,14.833,59.415,15.31,58.782z M14.397,62.298h2.189
       c0.062,0.233,0.153,0.454,0.274,0.656L15.314,64.5C14.838,63.871,14.513,63.119,14.397,62.298z M18.443,66.328
       c-0.818-0.112-1.564-0.434-2.193-0.908l1.537-1.538c0.202,0.118,0.424,0.205,0.656,0.266V66.328z M18.443,59.116
       c-0.238,0.062-0.461,0.157-0.668,0.279l-1.541-1.541c0.633-0.48,1.385-0.8,2.209-0.913V59.116z M19.752,56.941
       c0.826,0.113,1.577,0.433,2.209,0.914l-1.54,1.54c-0.207-0.122-0.43-0.218-0.669-0.279V56.941z M19.752,66.328v-2.182
       c0.233-0.061,0.454-0.147,0.657-0.268l1.538,1.54C21.317,65.894,20.572,66.214,19.752,66.328z M22.885,64.504l-1.551-1.551
       c0.12-0.203,0.22-0.42,0.282-0.655h2.172C23.673,63.119,23.364,63.875,22.885,64.504z M21.617,60.99
       c-0.06-0.236-0.153-0.459-0.274-0.665l1.543-1.543c0.478,0.633,0.792,1.383,0.905,2.208H21.617z"
      />
      <path
        d="M83.965,54.071c-4.176,0-7.561,3.383-7.561,7.56c0,0.324,0.025,0.641,0.065,0.951c0.468,3.729,3.643,6.611,7.494,6.611
       c3.958,0,7.201-3.042,7.53-6.916c0.018-0.214,0.031-0.428,0.031-0.646C91.526,57.454,88.142,54.071,83.965,54.071z M80.177,58.782
       l1.543,1.543c-0.12,0.206-0.214,0.429-0.273,0.665h-2.175C79.385,60.165,79.7,59.415,80.177,58.782z M79.265,62.298h2.19
       c0.062,0.233,0.152,0.454,0.272,0.656L80.182,64.5C79.705,63.871,79.38,63.119,79.265,62.298z M83.31,66.328
       c-0.818-0.112-1.563-0.434-2.192-0.908l1.537-1.538c0.201,0.118,0.424,0.205,0.655,0.266V66.328z M83.31,59.116
       c-0.237,0.062-0.461,0.157-0.669,0.279L81.1,57.854c0.634-0.48,1.385-0.8,2.209-0.913L83.31,59.116L83.31,59.116z M84.62,56.941
       c0.824,0.113,1.576,0.433,2.209,0.914l-1.541,1.54c-0.207-0.122-0.431-0.218-0.668-0.279V56.941z M84.62,66.328v-2.182
       c0.231-0.061,0.454-0.147,0.655-0.268l1.538,1.54C86.184,65.894,85.438,66.214,84.62,66.328z M87.752,64.504l-1.551-1.551
       c0.12-0.203,0.22-0.42,0.281-0.655h2.174C88.54,63.119,88.23,63.875,87.752,64.504z M86.483,60.99
       c-0.06-0.236-0.152-0.459-0.272-0.665l1.542-1.543c0.478,0.633,0.792,1.383,0.906,2.208H86.483z"
      />
      <path
        d="M99.091,47.939c-0.056-1.67-0.516-3.301-1.339-4.754l-5.43-9.581c-0.89-1.569-2.521-2.573-4.322-2.664
       c-9.1-0.456-37.002-1.618-45.786,0.744C36.272,33.283,21.278,43.14,21.278,43.14S4.781,45.695,1.634,53.219
       c0,0-1.358,0.793-1.605,2.826c-0.127,1.046,0.183,2.634,0.525,3.965c0.375,1.456,1.582,2.552,3.067,2.783l7.16,1.122
       c-0.107-0.391-0.196-0.788-0.248-1.198c-0.045-0.354-0.075-0.716-0.075-1.087c0-4.763,3.875-8.637,8.639-8.637
       c4.765,0,8.64,3.874,8.64,8.637c0,0.249-0.016,0.493-0.036,0.735c-0.072,0.844-0.268,1.651-0.567,2.408l0.842,0.045l47.568-1.287
       c-0.061-0.268-0.109-0.538-0.145-0.814c-0.046-0.354-0.074-0.716-0.074-1.087c0-4.763,3.875-8.637,8.638-8.637
       c4.765,0,8.64,3.874,8.64,8.637c0,0.249-0.016,0.493-0.037,0.735c-0.013,0.16-0.041,0.315-0.062,0.473L96.609,62
       c1.693-0.346,2.891-1.86,2.831-3.589L99.091,47.939z M71.715,32.71l1.093,10.911l-16.774,0.686V32.655
       C60.938,32.542,66.536,32.593,71.715,32.71z M29.387,45.087l-1.659,0.093c-0.451,0.025-0.864-0.249-1.016-0.675
       c-0.152-0.424-0.005-0.897,0.358-1.164c0.975-0.712,2.169-1.563,3.499-2.462v2.784C29.759,44.348,29.387,45.087,29.387,45.087z
        M33.498,42.533c-0.105,0.004-0.191,0.03-0.291,0.04V39.15c3.382-2.144,7.215-4.273,10.511-5.34c1.5-0.485,4.236-0.795,7.636-0.98
       v11.668l-14.412,0.589C36.942,45.087,36.442,42.423,33.498,42.533z M91.768,41.475c-0.503,0.874-1.419,1.429-2.426,1.471
       L77.49,43.43l-1.062-10.594c4.898,0.149,8.99,0.333,11.063,0.445c0.959,0.051,1.824,0.604,2.271,1.454l2.057,3.903
       C92.29,39.531,92.27,40.603,91.768,41.475z"
      />
    </svg>,
    <svg
      id="Capa_1"
      className={servicePageStyles.carIcon}
      height="512"
      viewBox="0 0 512 512"
      width="512"
    >
      <path d="m462.678 265.692 49.322-8.692-15.848-89.9-49.28 8.691c-6.818-16.25-15.657-31.431-26.18-45.257l32.193-38.376-69.934-58.68-32.161 38.345c-15.361-7.929-31.844-13.985-49.153-17.88v-50.052h-91.276v50.052c-17.309 3.896-33.792 9.951-49.153 17.88l-32.161-38.344-69.933 58.681 32.193 38.376c-10.523 13.826-19.362 29.007-26.18 45.257l-49.28-8.691-15.847 89.898 49.322 8.691c.847 17.859 3.949 35.115 9.03 51.513l-43.33 25.016 45.648 79.049 43.383-25.048c11.846 12.81 25.291 24.137 40.027 33.622l-17.129 47.046 85.782 31.219 17.129-47.046c8.554 1.08 17.288 1.641 26.138 1.641s17.584-.561 26.138-1.641l17.129 47.046 85.781-31.219-17.129-47.046c14.736-9.485 28.181-20.813 40.027-33.622l43.383 25.048 45.648-79.049-43.33-25.016c5.082-16.397 8.184-33.653 9.031-51.512zm-206.678 85.443c-52.657 0-95.341-42.684-95.341-95.341s42.684-95.341 95.341-95.341 95.341 42.684 95.341 95.341-42.684 95.341-95.341 95.341z" />
    </svg>,
  ]

  return (
    <Layout
      title={nodeData.frontmatter.metaTitle}
      description={metaDescription}
      image={metaImg}
    >
      <div className={servicePageStyles.container}>
        <Breadcrumb
          crumbs={crumbs}
          crumbSeparator=" > "
          crumbLabel={customCrumbLabel}
        />
        <ScrollAnimation animateIn="animate__fadeIn" animateOnce={true}>
          <h1 style={{ textTransform: "uppercase" }}>
            {nodeData.frontmatter.h1 ? (
              nodeData.frontmatter.h1
            ) : (
              <>
                <span
                  style={{
                    color: "#ff6633",
                    fontWeight: 600,
                  }}
                >
                  {nodeData.frontmatter.category}
                </span>{" "}
                {nodeData.frontmatter.title}
              </>
            )}
          </h1>
        </ScrollAnimation>
        <div className={servicePageStyles.containerGrid}>
          <MDXProvider>
            <MDXRenderer
              frontmatter={nodeData.frontmatter}
              styles={servicePageStyles}
            >
              {nodeData.body}
            </MDXRenderer>
          </MDXProvider>
          {nodeData.frontmatter.priceNotRequired ? null : (
            <div>
              <h2>
                HOW MUCH DOES {nodeData.frontmatter.title.toUpperCase()} COST?
              </h2>
              {nodeData.frontmatter.priceSectionDescription ? (
                <p>{nodeData.frontmatter.priceSectionDescription}</p>
              ) : (
                <p>
                  Looking for how much it costs to get{" "}
                  {nodeData.frontmatter.title.toLowerCase()} done? See our price
                  breakdown below, if you have any questions please{" "}
                  <Link to="/contact/">get in touch!</Link>
                </p>
              )}
              <div className={servicePageStyles.priceCards}>
                <PriceSection
                  key="0"
                  size={nodeData.frontmatter.sizes[0] + " CAR"}
                  description={
                    "(" + nodeData.frontmatter.sizeDescriptions[0] + ")"
                  }
                  title={nodeData.frontmatter.title}
                  table={nodeData.frontmatter.table}
                  priceBasedOn="White 3-door hatchback"
                  packages={nodeData.frontmatter.packages}
                  fromPrices={nodeData.frontmatter.fromPrices}
                  packageNo="0"
                  icon={icons[0]}
                />
                <PriceSection
                  key="1"
                  size={nodeData.frontmatter.sizes[1] + " CAR"}
                  description={
                    "(" + nodeData.frontmatter.sizeDescriptions[1] + ")"
                  }
                  title={nodeData.frontmatter.title}
                  table={nodeData.frontmatter.table}
                  priceBasedOn="White 4-door saloon"
                  packages={nodeData.frontmatter.packages}
                  fromPrices={nodeData.frontmatter.fromPrices}
                  packageNo="1"
                  icon={icons[1]}
                />
                <PriceSection
                  key="2"
                  size={nodeData.frontmatter.sizes[2] + " CAR"}
                  description={
                    "(" + nodeData.frontmatter.sizeDescriptions[2] + ")"
                  }
                  title={nodeData.frontmatter.title}
                  table={nodeData.frontmatter.table}
                  priceBasedOn="White 5-door SUV"
                  packages={nodeData.frontmatter.packages}
                  fromPrices={nodeData.frontmatter.fromPrices}
                  packageNo="2"
                  icon={icons[2]}
                />
                <div id={priceSectionStyles.container}>
                  <div id={priceSectionStyles.priceCardContainer}>
                    <div id={priceSectionStyles.priceCard}>
                      {icons[3]}
                      <div id={priceSectionStyles.carSizeContainer}>
                        <p id={priceSectionStyles.carSizeHeading}>
                          OTHER VEHICLE
                        </p>
                      </div>
                      <div id={priceSectionStyles.tablePriceContainer}>
                        <span
                          className={priceSectionStyles.tablePriceOnRequest}
                        >
                          ON REQUEST
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <p className={servicePageStyles.portfolioParagraph}>
                  Contact us today for a free quote or if you have any questions
                  about the service.
                </p>
                <Link to="/contact/" style={{ justifySelf: "center" }}>
                  <button className="buttonStyle1">GET A FREE QUOTE</button>
                </Link>
              </div>
              {nodeData.frontmatter.notes && (
                <div className={priceSectionStyles.notes}>
                  {nodeData.frontmatter.notes.map((note, index) => {
                    return (
                      <p key={index}>
                        <span style={{ color: "#bb7e6a" }}>NOTE: </span>
                        <span dangerouslySetInnerHTML={{ __html: note }} />
                        <br />
                      </p>
                    )
                  })}
                </div>
              )}
            </div>
          )}
          <ProjectsSection
            data={data}
            serviceTitle={nodeData.frontmatter.title}
          />
          <FAQSection nodeData={nodeData} />
          <WaitingSection
            servicePage={true}
            serviceTitle={nodeData.frontmatter.title}
            waitingSectionTitle={nodeData.frontmatter.waitingSectionTitle}
          />
        </div>
      </div>
      <ContactSection
        servicePage={true}
        serviceTitle={nodeData.frontmatter.title}
        contactSectionDescription={
          nodeData.frontmatter.contactSectionDescription
        }
      />
    </Layout>
  )
}

class ProjectsSection extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      portfolio: true,
    }
  }

  componentDidMount() {
    const slider = document.getElementById(servicePageStyles.projectsGrid)
    let isDown = false
    let startX
    let scrollLeft

    slider.addEventListener("mousedown", e => {
      isDown = true
      startX = e.pageX - slider.offsetLeft
      scrollLeft = slider.scrollLeft
    })
    slider.addEventListener("mouseleave", () => {
      isDown = false
    })
    slider.addEventListener("mouseup", () => {
      isDown = false
    })
    slider.addEventListener("mousemove", e => {
      if (!isDown) return
      e.preventDefault()
      const x = e.pageX - slider.offsetLeft
      const walk = (x - startX) * 2 //scroll-fast
      slider.scrollLeft = scrollLeft - walk
    })
  }

  render() {
    const { data } = this.props
    const nodeData = data.mdx
    const portfolioProjects = []

    return (
      <div className={homeStyles.projectsContainer}>
        <h2>
          {nodeData.frontmatter.projectsSectionTitle
            ? nodeData.frontmatter.projectsSectionTitle
            : `EXAMPLES OF OUR ${this.props.serviceTitle.toUpperCase()} WORK`}
        </h2>
        {nodeData.frontmatter.projectsSectionDescription && (
          <p>{nodeData.frontmatter.projectsSectionDescription}</p>
        )}
        <div
          className={homeStyles.projectsGrid}
          id={servicePageStyles.projectsGrid}
        >
          {data.allMdx.edges.map((project, index) => {
            if (
              project.node.frontmatter.services.includes(
                data.mdx.frontmatter.shortTitle
              )
            ) {
              portfolioProjects.push(
                <ProjectCard
                  project={project.node.frontmatter}
                  link={project.node.fields.slug}
                  styles={homeStyles}
                  key={index}
                />
              )
            }
          })}
          {portfolioProjects[0] ? (
            portfolioProjects.map(project => {
              return project
            })
          ) : (
            <p className={servicePageStyles.noProjectMessage}>
              Unfortunately, We don't have any projects with this service on our
              website yet... <br />
              <br /> But we might do on our{" "}
              <a
                href="https://www.facebook.com/tintexportsmouth/"
                rel="nofollow"
              >
                Facebook
              </a>{" "}
              and{" "}
              <a
                href="https://www.instagram.com/tintex_portsmouth/"
                rel="nofollow"
              >
                Instagram
              </a>
              !
            </p>
          )}
        </div>
        <p className={servicePageStyles.portfolioParagraph}>
          Visit our portfolio to see more examples and get inspired for your own
          vehicle.
        </p>
        <Link to="/portfolio/" style={{ justifySelf: "center" }}>
          <button className="buttonStyle1">SEE OUR PORTFOLIO</button>
        </Link>
      </div>
    )
  }
}

class FAQSection extends React.Component {
  render() {
    const { nodeData } = this.props

    if (nodeData.frontmatter.questions[0]) {
      return (
        <div>
          <h2>FREQUENTLY ASKED QUESTIONS (FAQ)</h2>
          <div className={servicePageStyles.faqGrid}>
            {nodeData.frontmatter.questions.map((question, index) => {
              return (
                <FAQQuestion
                  question={question}
                  index={index}
                  nodeData={nodeData}
                />
              )
            })}
          </div>
        </div>
      )
    } else {
      return <></>
    }
  }
}

class FAQQuestion extends React.Component {
  constructor(props) {
    super(props)
    this.state = { faqOpen: false }

    this.handleClick = this.handleClick.bind(this)
  }

  handleClick() {
    this.setState(state => ({ faqOpen: !state.faqOpen }))
  }

  render() {
    let nodeData = this.props.nodeData

    return (
      <div className={servicePageStyles.faqContainer} key={this.props.index}>
        <h2
          className={servicePageStyles.faqQuestion}
          onClick={this.handleClick}
        >
          {this.props.question}
          {this.state.faqOpen ? (
            <button className={servicePageStyles.openFaqBtn}>-</button>
          ) : (
            <button className={servicePageStyles.openFaqBtn}>+</button>
          )}
        </h2>
        <CSSTransition
          in={this.state.faqOpen}
          timeout={200}
          classNames="priceTable"
          unmountOnExit
        >
          <p className={servicePageStyles.faqAnswer}>
            {nodeData.frontmatter.answers[this.props.index]}
          </p>
        </CSSTransition>
      </div>
    )
  }
}

export const query = graphql`
  query ($slug: String!) {
    allImageSharp {
      nodes {
        fluid(maxWidth: 1440, quality: 100) {
          originalName
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    allMdx(
      filter: { frontmatter: { type: { eq: "Project" } } }
      sort: { fields: [frontmatter___title], order: ASC }
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            type
            category
            subCategory
            services
            title
            shortTitle
            description
            imgFile
          }
        }
      }
    }
    mdx(fields: { slug: { eq: $slug } }) {
      body
      frontmatter {
        category
        subCategory
        h1
        title
        shortTitle
        featureList
        priceSectionDescription
        priceNotRequired
        sizes
        sizeDescriptions
        metaTitle
        metaDescription
        table
        fromPrices
        questions
        answers
        packages {
          names
          prices
          time
          description
        }
        notes
        imgFile
        projectsSectionTitle
        projectsSectionDescription
        waitingSectionTitle
        contactSectionDescription
      }
    }
  }
`
