import React, { useState } from "react"
import * as priceSectionStyles from "./priceSection.module.css"
import { CSSTransition } from "react-transition-group"

import ScrollAnimation from "react-animate-on-scroll"

import "animate.css"

class PriceSection extends React.Component {
  constructor(props) {
    super(props)
    this.state = { tableOpen: false }

    this.handleClick = this.handleClick.bind(this)
  }

  componentDidMount() {
    if (this.props.fromPrices[this.props.packageNo] === "ON REQUEST") {
      const asterixCollection = document.getElementsByClassName(
        priceSectionStyles.tablePriceAsterix
      )

      for (let i = 0; i < asterixCollection.length; i++) {
        asterixCollection[i].style.display = "none"
      }

      const fromCollection = document.getElementsByClassName(
        priceSectionStyles.tablePriceFrom
      )

      for (let i = 0; i < fromCollection.length; i++) {
        fromCollection[i].style.display = "none"
      }

      const tablePriceCollection = document.getElementsByClassName(
        priceSectionStyles.tablePrice
      )

      for (let i = 0; i < tablePriceCollection.length; i++) {
        tablePriceCollection[i].classList.add(
          priceSectionStyles.tablePriceOnRequest
        )
      }
    }
  }

  handleClick() {
    if (this.props.table) {
      this.setState(state => ({
        tableOpen: !state.tableOpen,
      }))
    }
  }

  render() {
    const packageNames = this.props.packages[0].names
    const packagePrices = this.props.packages[1].prices[this.props.packageNo]
    const packageTime = this.props.packages[2].time
    const packageDescription = this.props.packages[3].description

    const tableRows = []

    for (const [index] of packageNames.entries()) {
      tableRows.push(
        <tr key={index}>
          <td style={{ textAlign: "right" }}>
            {packageNames[index]}
            <br />
            <span className={priceSectionStyles.tablePackageDescription}>
              {packageDescription[index]}
            </span>
          </td>
          {packagePrices[index] != "ON REQUEST" ? (
            <td>
              <div
                id={priceSectionStyles.tablePriceContainer}
                style={{ justifyContent: "center" }}
              >
                <span className={priceSectionStyles.tablePriceFrom}>from</span>
                <div className={priceSectionStyles.tablePrice}>
                  <div style={{ display: "flex" }}>
                    {packagePrices[index]}{" "}
                    <span className={priceSectionStyles.tablePriceAsterix}>
                      *
                    </span>
                  </div>
                  <span className={priceSectionStyles.tablePriceVAT}>
                    + VAT
                  </span>
                </div>
              </div>
            </td>
          ) : (
            <td className={priceSectionStyles.tablePriceOnRequest}>
              {packagePrices[index]}
            </td>
          )}
          <td style={{ textAlign: "center" }}>{packageTime[index]}</td>
        </tr>
      )
    }

    return (
      <div id={priceSectionStyles.container}>
        <ScrollAnimation animateIn="animate__fadeIn" animateOnce={true}>
          <div
            id={priceSectionStyles.priceCardContainer}
            onClick={this.handleClick}
          >
            <div id={priceSectionStyles.priceCard}>
              {this.props.icon}
              <div id={priceSectionStyles.carSizeContainer}>
                <p id={priceSectionStyles.carSizeHeading}>
                  {this.props.size.toUpperCase()}
                </p>
                <p className={priceSectionStyles.headerExamples}>
                  {this.props.description}
                </p>
              </div>
              {this.props.table ? (
                <div>
                  {this.state.tableOpen ? (
                    <button id={priceSectionStyles.openTableBtn}>-</button>
                  ) : (
                    <button id={priceSectionStyles.openTableBtn}>+</button>
                  )}
                </div>
              ) : (
                <div id={priceSectionStyles.tablePriceContainer}>
                  <span className={priceSectionStyles.tablePriceFrom}>
                    from
                  </span>
                  <div className={priceSectionStyles.tablePrice}>
                    <div style={{ display: "flex" }}>
                      {this.props.fromPrices[this.props.packageNo]}
                      <span className={priceSectionStyles.tablePriceAsterix}>
                        *
                      </span>
                    </div>
                    {this.props.fromPrices[this.props.packageNo] !=
                      "ON REQUEST" && (
                      <span className={priceSectionStyles.tablePriceVAT}>
                        + VAT
                      </span>
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
          {this.props.table && (
            <CSSTransition
              in={this.state.tableOpen}
              timeout={200}
              classNames="priceTable"
              unmountOnExit
            >
              <div id={priceSectionStyles.tableContainer}>
                <table id={priceSectionStyles.table}>
                  <thead>
                    <tr>
                      <th></th>
                      <th>
                        PRICE
                        <span
                          className={priceSectionStyles.headerExamples}
                        ></span>
                      </th>
                      <th>
                        TIME
                        <spantableContainer
                          className={priceSectionStyles.headerExamples}
                        ></spantableContainer>
                      </th>
                    </tr>
                  </thead>
                  <tbody>{tableRows}</tbody>
                </table>
              </div>
            </CSSTransition>
          )}
          {this.state.tableOpen && (
            <span id={priceSectionStyles.priceBasedOn}>
              * Price based on {this.props.priceBasedOn}
            </span>
          )}
          {!this.props.table && (
            <span id={priceSectionStyles.priceBasedOn}>
              * Price based on {this.props.priceBasedOn}
            </span>
          )}
        </ScrollAnimation>
      </div>
    )
  }
}

export default PriceSection
